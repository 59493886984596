// import { Outlet } from 'react-router-dom';
// import Sidebar from './Sidebar';
// import { useEffect, useState } from 'react';

// const Dashboard = () => {
//     const [productsCount, setProductsCount] = useState(0);
//     const [flowersCount, setFlowersCount] = useState(0);

//     useEffect(() => {
//         const savedProducts = JSON.parse(localStorage.getItem('products')) || [];
//         const savedFlowers = JSON.parse(localStorage.getItem('flowers')) || [];
        
//         setProductsCount(savedProducts.length);
//         setFlowersCount(savedFlowers.length);
//     }, []);

//     const handleProductsChange = (count) => {
//         setProductsCount(count);
//     };

//     const handleFlowersChange = (count) => {
//         setFlowersCount(count);
//     };

//     return (
//         <div className="dashboard-container marg" style={{display:'flex'}}>
//             <Sidebar />
//             <div className="main-content">
//                 <h1 style={{textAlign:'center'}}>Dashboard</h1>
//                 <p> Num of Product: {productsCount}</p>
//                 <p>Num of Flowers:{flowersCount}</p>
//                 <Outlet 
//                     context={{ handleProductsChange, handleFlowersChange }} 
//                 />
//             </div>
//         </div>
//     );
// };

// export default Dashboard;
import { Outlet, useLocation } from 'react-router-dom'; // استيراد useLocation
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';

const Dashboard = () => {
    const [productsCount, setProductsCount] = useState(0);
    const [flowersCount, setFlowersCount] = useState(0);
    const location = useLocation(); // الحصول على الموقع الحالي

    useEffect(() => {
        const savedProducts = JSON.parse(localStorage.getItem('products')) || [];
        const savedFlowers = JSON.parse(localStorage.getItem('flowers')) || [];
        
        setProductsCount(savedProducts.length);
        setFlowersCount(savedFlowers.length);
    }, []);

    const handleProductsChange = (count) => {
        setProductsCount(count);
    };

    const handleFlowersChange = (count) => {
        setFlowersCount(count);
    };

    // تحقق مما إذا كانت الصفحة الحالية هي الصفحة الرئيسية
    const isDashboardPage = location.pathname === '/dashboard'; 

    return (
        <div className="dashboard-container marg" style={{display:'flex'}}>
            <Sidebar />
            <div className="main-content">
                {isDashboardPage && ( // إظهار المحتوى فقط إذا كانت الصفحة هي Dashboard
                    <>
                        <h1 style={{textAlign:'center'}}>Dashboard</h1>
                        <p> Num of Product: {productsCount}</p>
                        <p>Num of Flowers: {flowersCount}</p>
                    </>
                )}
                <Outlet 
                    context={{ handleProductsChange, handleFlowersChange }} 
                />
            </div>
        </div>
    );
};

export default Dashboard;
